<template>
  <v-card-title class="iq-color poppins px-0">
    <span>{{ $t("showing") }} &af;</span>
    <span>
      {{ perPage * page - perPage + 1 }} {{ $t("to") }}
      {{ toPage ? toPage : "1" }} {{ $t("of") }}
      {{ totalNumber ? totalNumber : "1" }}
    </span>
  </v-card-title>
</template>

<script>
export default {
  name: "PageInfo",
  props: ["page", "totalNumber", "perPage"],
  computed: {
    toPage: function () {
      if (this.perPage * this.page > this.totalNumber) {
        return this.totalNumber;
      } else {
        return this.perPage * this.page;
      }
    },
  },
};
</script>
