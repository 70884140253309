<template>
  <div class="ml-8 mr-6">
    <v-row class="mt-2 mb-1">
      <v-col
        class="ma-0 pa-0"
        cols="12"
        sm="6"
        md="4"
        lg="3"
        v-for="(fil, i) in filters"
        :key="i"
      >
        <MyFilter
          :filter="fil"
          @option="filterChange"
          :filtersData="queryObj"
          ref="filter"
          :submitFilterValues="submitFilterValues"
          :defaultValues="defaultValues"
        ></MyFilter>
      </v-col>
    </v-row>
    <div class="text-right mt-14">
      <GenerateEmail
        v-if="hasEmail"
        ref="generateEmail"
        :filters="men"
        @generateEmail="generateEmail"
      />
      <button
        class="btn iq-btn px-3 px-sm-4 py-2 poppins-ls"
        @click="applyFilters"
      >
        {{ $t("search") }}
      </button>
      <button
        class="ml-2 btn iq-btn px-3 px-sm-4 py-2 poppins-ls"
        @click="resetFilters"
      >
        {{ $t("clear") }}
      </button>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import ApiService from "@/core/services/api.service";
import MyFilter from "@/own/components/filter/MyFilter.vue";
import GenerateEmail from "@/own/components/reports/GenerateEmail.vue";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
export default {
  name: "Filters",
  components: { MyFilter, GenerateEmail },
  props: {
    submitFilterValues: {
      required: true,
      type: Function,
    },
    resetFilterValues: {
      required: true,
      type: Function,
    },
    setTableFiltersValues: {
      required: true,
      type: Function,
    },
    filters: {
      required: true,
      type: Array,
    },
    defaultValues: {
      required: false,
      type: Object,
    },
    table_properties: {
      required: true,
      type: Object,
    },
    name: {
      required: true,
      type: String,
    },
    pageLoader: {
      required: true,
      type: Function,
    },
    hasEmail: {
      required: true,
      type: Boolean,
    },
  },
  data: () => ({
    queryObj: {},
    men: [],
  }),
  methods: {
    applyFilters() {
      setTimeout(
        this.$nextTick(() => {
          setTimeout(() => {
            try {
              this.$refs.filter[0].$el.focus();
              this.setTableFiltersValues(this.men);
              this.submitFilterValues();
            } catch {
              return;
            }
          }, 10);
        })
      );
    },
    resetFilters() {
      this.$store.commit(SET_PAGE_LOADING, true);
      this.$refs.filter.forEach((filter) => {
        filter.clearData();
      });
      this.queryObj = {};
      this.men = [];
      this.resetFilterValues();
    },
    filterChange(val) {
      this.men = [];

      Object.entries(val).forEach((data) => {
        if (data[1] != null && data[0] != null) {
          this.queryObj[data[0]] = data[1];
        }
        if (data[0] == null || data[1] == null || data[1].length == 0) {
          delete this.queryObj[data[0]];
        }
      });

      Object.keys(this.queryObj).forEach((l) => {
        this.men.push({ name: l, value: this.queryObj[l] });
      });

      this.setTableFiltersValues(this.men);
    },
    generateEmail(email) {
      this.pageLoader(true);

      const payload = {
        email: email,
        name: this.name,
        title: this.table_properties.title,
        filters: this.men,
      };

      ApiService.post("settings/reports/store", payload)
        .then((response) => {
          this.serverData = response.data.data;
          this.pageLoader(false);
        })
        .then(() => {
          Swal.fire({
            title: this.$t("success"),
            text: this.$t("email_sent_successfully"),
            icon: "success",
            showConfirmButton: false,
            timer: 2500,
          });
          this.$refs.generateEmail.toggleModal();
        })
        .finally(() => {
          this.pageLoader(false);
        });
    },
  },
};
</script>
