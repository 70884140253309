var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ReportSimpleDataTable',{attrs:{"urls":_vm.urls,"mandatory-filter":[
    {
      name: 'date_range',
      title: 'Date range',
      valueFieldName: '.select',
    },
  ]}})
}
var staticRenderFns = []

export { render, staticRenderFns }